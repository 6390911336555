<template>
  <Head v-if="flat">
    <title v-if="!flat.title">
      {{ flatNameNormalize(flat.layout) }}
      {{ flat.layout.area }} м² / {{ flat.floor.number }} этаж в ЖК Very (№{{
        flat.number
      }}) — купить {{ isApartment ? "лот" : "квартиру" }} в новостройке
      ЖК VERY Ботанический сад в Москве от застройщика
    </title>

    <title v-else>
      {{ flat.title }}
    </title>
    <meta
      name="description"
      :content="
        `Продажа ${isApartment ? 'нового' : 'новой'} ` +
        flat.title ? flat.title : flatNameNormalize(flat.layout, true) +
        ' ' +
        flat.layout.area +
        ' м²' + flat.title ? '' : (
          ' / ' +
        flat.floor.number +
        ' этаж в ЖК Very (№' +
        flat.number +
        ') от застройщика ГК ОСНОВА на ул Ботаническая 29 в Москве (СВАО, р-н Марфино) рядом с метро Фонвизинская/Петровско-Разумовская по цене ' +
        $filters.priceFormat(flat.discount_cost) 
        )
      "
    />
    <meta
      property="og:title"
      :content="
        flat.layout.room_count ? flatNameNormalize(flat.layout) : flat.title +
        ' ' +
        flat.layout.area +
        ' м²' + flat.title ? '' : (' / ' +
        flat.floor.number +
        ' этаж в ЖК Very (№' +
        flat.number +
        `) — купить ${
          isApartment ? 'лот' : 'квартиру'
        } в новостройке ЖК VERY Ботанический сад в Москве от застройщика`)
      "
    />
    <meta
      property="og:description"
      :content="
        `Продажа ${isApartment ? 'нового' : 'новой'} ` +
        flat.title ? flat.title : flatNameNormalize(flat.layout, true) +
        ' ' +
        flat.layout.area +
        ' м²' + flat.title ? '' : (
          ' / ' +
        flat.floor.number +
        ' этаж в ЖК Very (№' +
        flat.number +
        ') от застройщика ГК ОСНОВА на ул Ботаническая 29 в Москве (СВАО, р-н Марфино) рядом с метро Фонвизинская/Петровско-Разумовская по цене ' +
        $filters.priceFormat(flat.discount_cost) 
        )
      "
    />
    <meta
      property="og:image"
      content="https://api.gkosnova.tech/public/storage/media/2022/2/1200/oXzorkK8sRMBrx0yMrKq191wHUQIZLqNza1j99ke.jpg"
    />
  </Head>
  <main class="main">
    <div class="container">
      <Backlink class="-small" :to="backlink" :text="backlink.title" />
    </div>
    <div v-if="flat" class="plan-flat" :class="{'commerce-flat': $route.meta.isCommerce}">
      <div class="flat">
        <div class="container">
          <div class="row">
            <div class="col-lg order-lg-1">
              <div class="flat__right">
                <div class="row">
                  <div class="col-xl">
                    <div class="plan__wrap">
                      <div
                        v-if="flat.layout.plan_image"
                        class="flat__plan plan"
                      >
                        <img
                          :style="{
                            backgroundImage:
                              'url(' + flat.layout.plan_image.image_thumb + ')',
                          }"
                          :class="[
                            'cursor-zoom',
                            {
                              'plan-stub': flat.layout.plan_image.stub,
                            },
                          ]"
                          :src="flat.layout.plan_image.source"
                          width="560"
                          height="560"
                          loading="lazy"
                          alt="Планировка"
                          @click.prevent="
                            $vfm.show({
                              component: 'PopupGallery',
                              bind: {
                                title:
                                  flat.title ? flat.title : $filters.flatNameNormalize(flat.layout) +
                                  ', №' +
                                  flat.number,
                                img: [{ src: flat.layout.plan_image.source }],
                              },
                            })
                          "
                        />
                        <span
                          v-if="flat.layout.plan_image.stub"
                          class="plan__text"
                        >
                          Актуальная планировка будет добавлена в ближайшее
                          время
                        </span>
                        <div class="plan__sun d-none d-xl-block">
                          <img
                            loading="lazy"
                            :src="videos ? require('@i/plan-flat-plan-compact.svg') : require('@i/plan-flat-plan.svg')"
                            alt="Где светит солнце"
                          />
                        </div>
                        <div v-if="flat.properties.with_design_project" class="plan__near near">
                          <div class="near__item -n">
                            <span @click="
                              $vfm.show({
                                component: 'PopupPanorama',
                                bind: {
                                  title: '3D-тур',
                                  src: 'https://gkosnova.tech/panoramas/panorama-very/botsad/360-osnova/design/',
                                },
                              })
                            ">
                              <img
                                :src="require('@i/html/tour/room-1-t.jpg')"
                                width="26"
                                height="26"
                                loading="lazy"
                                alt=""
                              />
                              <a href="javascript:void(0)" class="cursor-zoom"
                                >3D-тур</a
                              >
                            </span>
                          </div>
                        </div>
                        <div class="plan__north" :class="{'plan__north--to-top': videos}">
                          <Icon icon="north" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-auto">
                    <div
                      class="flat__sidebar d-flex flex-column flex-sm-row flex-xl-column justify-content-between align-items-center"
                      :class="{'flat__sidebar--curved': videos}"
                    >
                      <div
                        v-if="flat.floor.plan_image"
                        class="flat__floor floor"
                      >
                        <div class="floor__title d-xl-none">
                          Расположение на&nbsp;этаже
                        </div>
                        <div class="floor__img">
                          <img
                            loading="lazy"
                            :src="flat.floor.plan_image.image_thumb"
                            :width="flat.floor.plan_image.width"
                            :height="flat.floor.plan_image.height"
                            alt="Расположение на этаже"
                          />
                          <svg
                            class="floor__svg"
                            :viewBox="
                              '0 0 ' +
                              flat.floor.plan_image.width +
                              ' ' +
                              flat.floor.plan_image.height
                            "
                          >
                            <polygon :points="flat.area_points" />
                          </svg>
                          <a
                            href="javascript:void(0)"
                            class="stretched-link cursor-zoom"
                            @click="
                              $vfm.show({
                                component: 'PopupFloor',
                                bind: {
                                  title: 'Расположение на&nbsp;этаже',
                                  img: flat.floor.plan_image,
                                  points: flat.area_points,
                                },
                              })
                            "
                          />
                        </div>
                      </div>
                      <BuildingsPlan
                        class="flat__building cursor-zoom"
                        :src="$route.meta.isCommerce ? flat.properties.genplan : null"
                        @click="
                          $vfm.show({
                            component: 'PopupBuildingsPlan',
                            bind: {
                              title: 'Генеральный план',
                              src: $route.meta.isCommerce ? flat.properties.genplan : null
                            },
                          })
                        "
                      />
                      <FlatViewTrigger
                        v-if="videos"
                        class="flat__trigger cursor-zoom"
                        @click="$vfm.show(
                          {
                            component: 'PopupLive',
                            bind: {
                              videos: videos,
                              title: 'Виды из окна'
                            },
                          }
                          )"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-auto order-lg-0">
              <div class="flat__left">
                <h1
                  :class="[
                    'flat__title',
                    'h4',
                    { mb: showTags },
                  ]"
                >
                  {{ flat.title ? flat.title : flatNameNormalize(flat.layout) }}
                  {{ flat.layout.area }}&nbsp;м&sup2;{{flat.title ? '' : '&nbsp;/'
                  + flat.floor.number + '&nbsp;этаж в&nbsp;ЖК&nbsp;Very (№' + flat.number + ')'
                  }}
                </h1>
                <div class="flat__favourite">
                  <FavouritesIcon :id="flat.id" />
                </div>
                <div v-if="showTags" class="flat__tags">
                  <div
                    v-if="flat.status === 'booked'"
                    class="flat__tag btn btn-tag btn-orange"
                  >
                    <Icon icon="lock2" />{{ $filters.bookedStatusNormalize(flat.layout.type) }}
                  </div>
                  <div
                    v-if="flat.properties._with_finished_layout"
                    class="flat__tag flat__tag--stroke btn btn-tag btn-lime"
                  >
                    <Icon icon="roller" />c отделкой
                  </div>
                  <div
                    v-if="flat.properties._with_layout_furniture"
                    class="flat__tag btn btn-tag btn-lime"
                  >
                    <Icon icon="roller" />отделка + мебель
                  </div>
                  <div
                    v-if="flat.properties._with_layout"
                    class="flat__tag btn btn-tag btn-lime"
                  >
                    <Icon icon="roller" />доступна отделка
                  </div>
                  <div
                    v-if="flat.properties.with_design_project"
                    class="flat__tag btn btn-tag btn-lime"
                  >
                    <Icon icon="gift" />дизайн-проект в подарок
                  </div>
                  <div
                    v-if="flat.properties.is_assignment"
                    class="flat__tag btn btn-tag btn-dark-grey "
                  >
                    <Icon icon="key" />переуступка
                  </div>
                  <div
                    v-if="flat.discount"
                    class="flat__tag btn btn-tag btn-lime"
                  >
                    <Icon icon="percent" />акция
                  </div>
                </div>
                <div class="flat__info info">
                  <div v-if="flat.layout.area" class="info__row">
                    <div class="info__td">
                      <span>Общая площадь</span>
                    </div>
                    <div class="info__td">
                      <span v-html="$filters.squareFormat(flat.layout.area)" />
                    </div>
                  </div>
                  <div v-if="sortedRooms" class="info-small">
                    <div
                      v-for="room in sortedRooms"
                      :key="room.number"
                      class="info__row"
                    >
                      <div class="info__td">
                        <span>{{ room.number }}. {{ room.name }}</span>
                      </div>
                      <div class="info__td">
                        <span v-html="$filters.squareFormat(room.area)" />
                      </div>
                    </div>
                  </div>
                  <div v-if="flat.floor" class="info__row">
                    <div class="info__td">
                      <span>Этаж</span>
                    </div>
                    <div class="info__td">
                      <span>{{ flat.floor.number }}</span>
                    </div>
                  </div>
                  <div v-if="flat.building" class="info__row">
                    <div class="info__td">
                      <span>Корпус</span>
                    </div>
                    <div class="info__td">
                      <span>{{ flat.building }}</span>
                    </div>
                  </div>
                  <div v-if="flat.meter_cost" class="info__row">
                    <div class="info__td">
                      <span>Цена за м&sup2;</span>
                    </div>
                    <div class="info__td">
                      <span>{{ $filters.priceFormat(flat.meter_cost) }}</span>
                    </div>
                  </div>
                  <div class="info__row" v-if="!$route.meta.isCommerce">
                    <div class="info__td">
                      <span>Ипотека</span>
                    </div>
                    <div class="info__td primary">
                      <span>
                        от
                        {{
                          $filters.priceFormat(
                            $filters.mortgagePay({
                              cost: flat.discount_cost,
                              initPay: flat.discount_cost * mortgageProps[flat.layout.type].initialPayment / 100,
                              applyCoef: mortgage.apply_coefficient,
                              coef: mortgage.coefficient,
                              percent: mortgageProps[flat.layout.type].calcBet,
                              years: 30,
                            })
                          )
                        }}&nbsp;/&nbsp;мес.
                      </span>
                      <span class="mortgage__mark" v-if=mortgage.footnote>*</span>
                    </div>
                  </div>
                  <div v-if="flat.status" class="info__row">
                    <div class="info__td">
                      <span>Статус</span>
                    </div>
                    <div class="info__td" :class="{'info__td--green': flat.status === 'booked'}">
                      <span>{{ statusNormalize(flat.status) }}</span>
                    </div>
                  </div>
                </div>
                <div v-if="!flat.rent && flat.cost" class="flat__price price">
                  <div class="price__label">Стоимость</div>
                  <div class="price__cost">
                    <span class="price__cost-new" v-if="flat.discount">{{ $filters.priceFormat(flat.discount_cost) }}</span>
                    <span :class="{ 'price__cost-old': flat.discount }">{{ $filters.priceFormat(flat.cost) }}</span>
                  </div>
                </div>
                <div v-if="flat.rent" class="flat__price price">
                  <div class="price__label">Стоимость</div>
                  <div class="price__cost">
                    <span>{{ $filters.priceFormat(flat.rent) }} / мес</span>
                  </div>
                </div>
                <!-- <div class="flat__action action">
                  <div class="action__label">
                    <span>Акция</span>
                  </div>
                  <div class="action__text">
                    Беспроцентная рассрочка на&nbsp;4&nbsp;месяца при покупке до&nbsp;13&nbsp;октября.<br>
                    <a href="">Узнать подробнее</a>.
                  </div>
                </div> -->
                <div class="flat__btns d-sm-flex justify-content-lg-between">
                  <button
                    class="btn btn-default btn-small"
                    data-popup-with-chats
                    data-popup-with-chats-title="Заказать звонок"
                  >
                    Заказать звонок
                  </button>
                  <Share :flat-number="flat.number" class="flat__share" />
                </div>
                <p class="flat__footnote">{{ mortgage.footnote }}</p>
                <Feats
                  v-if="flat.properties"
                  class="flat__feats"
                  :list="$route.meta.isCommerce ? flat.properties.props : null"
                  :properties="flat.properties"
                />
                <Mortgage v-if="!$route.meta.isCommerce && !isApartment" class="flat__mortgage" />
                <TradeIn v-if="!$route.meta.isCommerce" class="flat__tradein" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Vars v-if="vars.length > 0" class="flat__vars" :vars="vars" />
      <div v-if="!$route.meta.isCommerce && related.length > 0" class="related">
        <div class="container">
          <div class="h2 related__title">
            Похожие
            {{
              $filters.flatNameNormalize({
                type: flat.layout.type,
                plural: true,
                lowercase: true,
              })
            }}
          </div>
          <Flats v-if="mq.lgPlus" :limit="5" :flats="related" />
          <FlatsMobile v-else :limit="5" :flats="related" />
          <div class="related__btn">
            <router-link :to="{ name: 'PlanSearch' }" class="btn btn-default">
              Посмотреть все предложения
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import _orderBy from "lodash/orderBy";
import Backlink from "@/components/UI/Backlink.vue";
//import { gsap } from 'gsap';
import Feats from "@/components/flat/FlatFeats.vue";
import Vars from "@/components/flat/FlatVars.vue";
import Share from "@/components/flat/FlatShare.vue";
import Flats from "@/components/FlatsTable.vue";
import FlatsMobile from "@/components/FlatsTableMobile.vue";
import Mortgage from "@/components/flat/FlatMortgage.vue";
import TradeIn from "@/components/flat/FlatTradeIn.vue";
import FavouritesIcon from "@/components/UI/FavouritesIcon.vue";
import BuildingsPlan from "@/components/planFloor/BuildingsPlan.vue";
import FlatViewTrigger from "@/components/flat/FlatViewTrigger.vue";
import flatsVideo from '@/content/flat.js'
import commerceFlats from "@/content/commerce.js";
import unicLots from "@/content/unicLots.js";
import { Head } from "@vueuse/head";
export default {
  components: {
    Head,
    Backlink,
    Feats,
    Vars,
    Share,
    Flats,
    FlatsMobile,
    Mortgage,
    TradeIn,
    FavouritesIcon,
    BuildingsPlan,
    FlatViewTrigger,
  },
  inject: ["mq", "mainStore"],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.mq.mdMinus) {
        vm.backlink = { title: "К списку квартир", name: "PlanSearch" };
      } else if (from.name == "PlanSearch") {
        vm.backlink = { title: "Подбор по параметрам", name: "PlanSearch" };
      }
    });
  },
  data() {
    return {
      flat: null,
      related: [],
      vars: [],
    };
  },
  computed: {
    showTags() {
      const { status, discount, properties } = this.flat;
      return status === 'booked' ||
        discount ||
        properties._with_layout ||
        properties._with_finished_layout ||
        properties._with_layout_furniture ||
        properties.with_design_project ||
        properties.is_assignment;
    },
    additionFlats () {
      return commerceFlats.concat(unicLots)
    },
    sortedRooms() {
      let result = "";
      if (this.flat.layout.variants.length > 0) {
        result = _orderBy(this.flat.layout.variants[0].rooms, "number", "asc");
      }
      return result;
    },
    commerceFlats () {
      return this.additionFlats
    },
    backlink () {
      if (this.$route.meta.isUnic) {
        return {
          title: "Назад к списку",
          name: "PlanUnicLotsSearch",
        }
      }
      if (this.$route.meta.isCommerce) {
        return {
          title: "Назад к списку",
          name: "PlanCommerceSearch",
        }
      }
      if (this.$route.meta.isSearch) {
        return {
          title: "Назад к списку",
          name: "PlanSearch",
        }
      }
      return {
        title: "Назад к списку",
        name: "PlanFloor",
        params: {
          korp: this.$route.params.korp,
          floor: this.$route.params.floor,
        },
      }
      
    },
    isApartment() {
      return this.flat?.layout.type === "apartment";
    },
    mortgage() {
      return this.mainStore.state.data?.mortgage ?? {};
    },
    mortgageProps () {
      return this.mainStore.getMortgageProps()
    },
    videos () {
      if (!this.flat) {
        return null
      }
      return flatsVideo[this.flat.number]
    }
  },
  async mounted() {
    try {
      let flat = null

      if (!this.$route.meta.isCommerce) {
        let data = await axios.get(
          this.$endpoint + "building-objects/property/" + this.$route.params.id
        );
        flat = data.data.data
      } else {
        this.commerceFlats.forEach(item => {
          if (item.uuid == this.$route.params.id) {
            flat = item
            return
          }
        })
      }
      this.$addPlanStub(flat);
      this.flat = flat;
      const {
        data: { data: related },
      } = await axios.get(
        this.$endpoint + "building-objects/" + this.$project + "/properties"
      );
      this.related = related.flatMap((flat) => {
        if (
          !(
            flat.layout.room_count === this.flat.layout.room_count &&
            flat.building === this.flat.building &&
            flat.id !== this.flat.id &&
            flat.floor.number > 0
          )
        ) {
          return [];
        }
        this.$addPlanStub(flat);
        return [flat];
      });
    } catch (err) {
      console.log(err);
      this.$Progress.fail();
    } finally {
      this.$Progress.finish();
    }
  },
  methods: {
    flatNameNormalize({ type, room_count: roomCount }, declension = false) {
      const form = declension ? "declension" : "singular";
      switch (roomCount) {
        case "0":
        case "S": {
          const NAMES = {
            flat: {
              singular: "Квартира-студия",
              declension: "квартиры-студии",
            },
            apartment: {
              singular: "Студия",
              declension: "Студии",
            },
          };
          return NAMES[type]?.[form] ?? "";
        }
        default: {
          const POSTFIXES = {
            flat: {
              singular: "комнатная квартира",
              declension: "комнатной квартиры",
            },
            apartment: {
              singular: "комнатный лот",
              declension: "комнатного лота",
            },
          };
          const postfix = POSTFIXES[type]?.[form];
          return postfix ? `${roomCount.replace("E", "")}-${postfix}` : "";
        }
      }
    },
    statusNormalize(status) {
      switch (status) {
        case "available":
          return "Свободно";
        case "booked":
          return "Забронировано";
        case "sold":
          return "Продано";
        default:
          return status;
      }
    },
  },
};
</script>

<style scoped>
.back-link {
  margin-top: -20px;
}

.flat {
  margin-bottom: 80px;
}

.flat:last-child {
  margin-bottom: 0;
}

.flat__title {
  margin-bottom: 48px;
  padding-right: 55px;
}

.flat__title.mb {
  margin-bottom: 12px;
}

.flat__tag {
  margin-right: 12px;
  margin-bottom: 5px;
}

.flat__tags {
  margin-bottom: 31px;
  margin-right: -12px;
  display: flex;
  flex-wrap: wrap;
}

.flat__tag--stroke {
  background-color: #fff!important;
  color: #205640!important;
  border-color: #205640!important;
}

.flat__favourite {
  position: absolute;
  top: 16px;
  right: 0;
}

.flat__favourite .favourite-icon {
  width: 44px;
  height: 44px;
}

.flat__left {
  position: relative;
  width: 370px;
  padding-top: 20px;
}

.flat__right {
  position: sticky;
  top: 196px;
}

.flat__sidebar {
  width: 195px;
  height: 100%;
}

.flat__sidebar:not(.flat__sidebar--curved)::before {
  content: "";
  height: 80px;
}

.flat__floor {
  position: relative;
}

.floor__title {
  font-size: 16px;
  line-height: 1.25;
  text-align: center;
}

.floor__img {
  position: relative;
}

.flat__info {
  margin-bottom: 23px;
  padding-bottom: 23px;
  border-bottom: 1px solid #e5e5e5;
}

.flat__info:last-child {
  margin-bottom: 0;
}

.flat__info .info-small {
  padding-bottom: 8px;
  padding-left: 20px;
}

.flat__footnote {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #3F4440;
}

.info__td.primary {
  color: #205640;
  font-weight: 500;
}

.info__td--green {
  color: #509C3B;
}

.price {
  margin-bottom: 25px;
}

.price:last-child {
  margin-bottom: 0;
}

.price__cost {
  font-weight: 500;
  font-size: 24px;
  align-items: center;
  display: flex;
}

.price__cost-new {
  color: #509C3B;
}
.price__cost-old {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-decoration-line: line-through;
  color: #C5C7C6;
  margin-left: 4px;
}

.action {
  position: relative;
  display: flex;
  width: 100%;
  margin-top: -5px;
  margin-bottom: 25px;
  padding: 16px;
  border-radius: 4px;
  background: #f1f7f0;
}

.action__label {
  margin-right: 10px;
  line-height: 1;
}

.action__label span {
  display: inline-block;
  padding: 7px 5px;
  border-radius: 2px;
  background: #509c3b;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

.action__text {
  font-weight: normal;
  font-size: 16px;
}

.flat__btns {
  margin-bottom: 16px;
}

.flat__btns:last-child {
  margin-bottom: 0;
}

.flat__feats {
  max-width: 320px;
  margin-bottom: 40px;
}

.flat__feats:last-child {
  margin-bottom: 0;
}

.flat__mortgage {
  max-width: 320px;
  margin-bottom: 55px;
}

.flat__mortgage:last-child {
  margin-bottom: 0;
}

.flat__tradein {
  margin-bottom: 55px;
}

.flat__tradein:last-child {
  margin-bottom: 0;
}

.plan {
  position: relative;
  margin-top: -20px;
  padding-bottom: 100%;
}

.plan__wrap {
  max-width: 550px;
  margin: 0 auto 50px;
}

.plan > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-size: cover;
}

.plan > img.plan-stub {
  opacity: 0.08;
  pointer-events: none;
}

.plan__text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 378px;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  transform: translate(-50%, -50%);
}

.plan__sun {
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -324px;
  margin-left: -120px;
  pointer-events: none;
}

.plan__sun img {
  max-width: none;
}

.plan__north {
  position: absolute;
  top: -48px;
  right: -141px;
}

.plan__north--to-top {
  position: absolute;
  top: -70px;
  right: -141px;
}

.near__item {
  position: absolute;
  width: 100%;
  text-align: center;
}

.near__item span {
  display: block;
  white-space: nowrap;
}

.near__item a {
  border-bottom: 1px dashed currentColor;
  font-weight: normal;
  text-decoration: none;
}

body.-notouch .near__item a:hover {
  border-color: transparent;
}

.near__item img {
  width: 26px;
  height: 26px;
  margin-top: -7px;
  margin-right: 4px;
  border-radius: 50%;
}

.near__item.-n {
  top: -51px;
}

.near__item.-s {
  bottom: -28px;
}

.floor {
  position: relative;
}

.floor img {
  height: auto;
}

.floor__svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.floor__svg polygon {
  opacity: 0.3;
  fill: #205640;
}

.flat__vars {
  overflow: hidden;
  margin-bottom: 80px;
}

.flat__vars:last-child {
  margin-bottom: 0;
}

.related {
  margin-bottom: 80px;
}

.related:last-child {
  margin-bottom: 0;
}

.related__btn {
  margin-top: 35px;
}

@media screen and (min-width: 1280px) {
  .flat__sidebar--curved div {
    width: 100%;
  }

  .flat__sidebar--curved div:nth-child(1) {
    margin-left: -40px;
  }
  .flat__sidebar--curved div:nth-child(2) {
    margin-right: -40px;
  }
  .flat__sidebar--curved div:nth-child(3) {
    margin-left: -40px;
  }
}
@media (max-width: 1279px) {
  /* .flat__left {
      width: 320px;
    } */
  .plan__wrap {
    margin-bottom: 0;
  }
  /* .flat__sidebar {
      width: 180px;
    } */
  .flat__sidebar {
    width: 100%;
    height: auto;
    margin-top: 10px;
    flex-wrap: wrap;
  }

  .flat__sidebar::before {
    display: none;
  }

  .plan__north {
    top: auto;
    right: auto;
    bottom: -100px;
    left: 50%;
    margin-left: -37px;
  }

  .flat__floor {
    width: 215px;
  }
  .flat__trigger {
    margin: auto;
    margin-top: 20px;
  }
}
@media (max-width: 991px) {
  .flat {
    margin-bottom: 60px;
  }

  .flat__title {
    margin-bottom: 27px;
  }

  .plan {
    margin-top: 50px;
  }

  .flat__left {
    width: 100%;
    max-width: 510px;
    margin: 40px auto 0;
    padding: 0;
  }

  .flat__feats {
    max-width: 100%;
  }

  .flat__mortgage {
    max-width: 100%;
  }

  .plan__wrap {
    max-width: 510px;
  }

  .flat__sidebar {
    max-width: 510px;
    margin-top: 10px;
    margin-right: auto;
    margin-left: auto;
  }

  .related__title {
    max-width: 510px;
    margin-right: auto;
    margin-bottom: 20px;
    margin-left: auto;
    font-size: 36px;
    letter-spacing: -0.01em;
  }

  .related__btn {
    max-width: 510px;
    margin-right: auto;
    margin-left: auto;
  }

  .flat__share {
    margin-left: 30px;
  }

  .flat__favourite {
    top: -4px;
  }

  .flat__vars {
    margin-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .flat__title {
    margin-bottom: 24px;
  }

  .flat__title.mb {
    margin-bottom: 8px;
  }

  .flat__tag {
    margin-bottom: 16px;
  }

  .related__title {
    text-align: left;
  }

  .related__btn {
    text-align: left;
  }

  .flat__info .info-small {
    font-size: 14px;
  }

  .plan__text {
    width: 269px;
    font-size: 18px;
  }
}
@media (max-width: 575px) {
  .flat__share {
    margin: 16px 0 0;
  }

  .flat__btns {
    margin-bottom: 16px;
  }

  .flat__sidebar {
    width: 190px;
  }

  .floor__title {
    font-weight: normal;
  }

  .plan__north {
    right: 0;
    left: auto;
    margin: 0;
  }
}
@media (max-width: 375px) {
  .flat__left {
    margin-top: 30px;
  }

  .flat__btns .btn {
    width: 100%;
  }

  .flat__share {
    width: 165px;
    margin-right: auto;
    margin-left: auto;
  }

  .related__btn .btn {
    width: 100%;
  }

  .flat__sidebar {
    margin-left: 0;
  }
}

.mortgage__mark {
  padding: 0 !important;
}
</style>
